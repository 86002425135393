export const data = [
  {
    name: "Snake",
    liveView: "https://quirky-goldstine-f22af2.netlify.app/",

    rating: 1,
    image: require("./assets/images/projects/snake.png"),
    tags: ["Game"],
  },
  {
    name: "Agency",
    liveView: "https://festive-volhard-d8b045.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/sunnyside-agency-landing-page",
    rating: 2,
    image: require("./assets/images/projects/agency.png"),
    tags: ["Components", "Landing Page"],
  },
  {
    name: "Dictionary",
    liveView: "https://gb-dictionary.netlify.app",
    githubLink: "https://github.com/Gamygams1234/dictionary-app",
    rating: 4,
    image: require("./assets/images/projects/dictionary.jpg"),
    tags: ["API", "Assistance"],
  },
  {
    name: "Front End Quiz",
    liveView: "https://gb-front-end-quiz.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/front-end-quiz",
    rating: 4.2,
    image: require("./assets/images/projects/quiz.jpg"),
    tags: ["API", "Game", "Assistance"],
  },
  {
    name: "Pomodoro",
    liveView: "https://gb-pomodoro.netlify.app",
    githubLink: "https://github.com/Gamygams1234/pomodoro",
    rating: 4,
    image: require("./assets/images/projects/pomodoro.jpg"),
    tags: [ "Assistance"],
  },
  {
    name: "Guess the Imposter",
    liveView: "https://guess-the-imposter.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/guess-the-imposter",
    rating: 2,
    image: require("./assets/images/projects/imposter.png"),
    tags: ["Game"],
  },
  {
    name: "REST Countries API",
    liveView: "https://rest-countries-gb.netlify.app",
    rating: 4,
    image: require("./assets/images/projects/countries.jpg"),
    tags: ["API"],
  },
  {
    name: "Memory",
    liveView: "https://gb-memory-game-ga.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/ga-memory-game",
    rating: 4,
    image: require("./assets/images/projects/memory.jpg"),
    tags: ["Game", "Components"],
  },
  {
    name: "Devjobs",
    liveView: "https://gb-devjobs.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/devjobs-front",
    rating: 4.5,
    image: require("./assets/images/projects/devjobs.jpg"),
    tags: ["API", "Assistance"],
  },
  {
    name: "Tomogotchi",
    liveView: "https://gb-tamagotchi.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/ga-tamagotchi",
    rating: 2,
    image: require("./assets/images/projects/tomogotchi.png"),
    tags: ["Game"],
  },
  {
    name: "Campaign Page",
    liveView: "https://meet-samantha-gb.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/blueprint-interview",
    rating: 3,
    image: require("./assets/images/projects/campaign.png"),
    tags: ["Landing Page", "Components"],
  },
  {
    name: "Advice Generator",
    liveView: "https://hardcore-shockley-323ddc.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/advice-generator",
    rating: 2,
    image: require("./assets/images/projects/advice.jpg"),
    tags: ["API", "Components", "Assistance"],
  },
  {
    name: "Recipe Page",
    liveView: "https://recipe-page-gb.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/recipe-page-main/",
    rating: 2,
    image: require("./assets/images/projects/recipe.jpg"),
    tags: ["Components", "Landing Page"],
  },
  {
    name: "Age Calculator",
    liveView: "https://age-caclculator-gb.netlify.app/",
    // githubLink: "https://github.com/Gamygams1234/recipe-page-main/",
    rating: 2,
    image: require("./assets/images/projects/age-calculator.jpg"),
    tags: ["Components", "Assistance"],
  },
  {
    name: "Tip Calculator",
    liveView: "https://brave-yalow-103a61.netlify.app/",
    githubLink: "https://github.com/Gamygams1234/tip-caluculator-app-splitter",
    rating: 3,
    image: require("./assets/images/projects/tip-calculator.jpg"),
    tags: ["Components", "Assistance"],
  },
  {
    name: "Coffee Roasters",
    liveView: "https://hopeful-nobel-26ed64.netlify.app",
    githubLink: "https://github.com/Gamygams1234/coffee-roasters-subscription",
    rating: 4.7,
    image: require("./assets/images/projects/coffeeroasters.jpg"),
    tags: ["Components", "API"],
  },
  {
    name: "TODO App",
    liveView: "https://gb-todos.netlify.app/",
    // githubLink: "https://github.com/Gamygams1234/coffee-roasters-subscription",
    rating: 4,
    image: require("./assets/images/projects/todo.jpg"),
    tags: ["Components"],
  },
  {
    name: "IP Address Tracker",
    liveView: "https://elated-hamilton-5af558.netlify.app/",
    // githubLink: "https://github.com/G???amygams1234/coffee-roasters-subscription",
    rating: 2,
    image: require("./assets/images/projects/ip-tracker.jpg"),
    tags: ["Components", "API"],
  },
  {
    name: "LoopStudios",
    liveView: "https://adoring-lovelace-465c9e.netlify.app/",
     githubLink: "https://github.com/Gamygams1234/loopstudios",
    rating: 2,
    image: require("./assets/images/projects/loopstudios.jpg"),
    tags: ["Components", "Landing Page"],
  },
  {
    name: "Insure Landing Page",
    liveView: "https://adoring-lovelace-465c9e.netlify.app/",
     githubLink: "https://github.com/Gamygams1234/insure-landing-page",
    rating: 2,
    image: require("./assets/images/projects/insure.jpg"),
    tags: ["Components", "Landing Page"],
  },
  {
    name: "MugLife",
    liveView: "https://muglife.onrender.com/",
     githubLink: "https://github.com/Gamygams1234/mug-life-frontend",
    rating: 4.2,
    image: require("./assets/images/projects/muglife.png"),
    tags: ["Components", "API" ],
  },
];
